import {Model} from "./Model"

export class ModelListener {
    private cache = {}

    constructor(private model: Model) {
        this.onModel = this.onModel.bind(this)

        model.dispatcher2.addListener(this.onModel)
        this.onModel()
    }

    private onModel() {
        const json_data = this.model.document.json_data

        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        if (!json_data.grade_type) {
            enable("application_career_information")

            enable("application_admission")
            enable("program_declaration")
            disable("petition_exception")
            enable("financial_aid")
            enable("submission")
        }

        if (
            json_data.request_exception_MSCSM === "yes" &&
            json_data.program_name === "Master of Science in Cybersecurity Management (MSCSM)"
        ) {
            enable("petition_exception")
        }

        if (
            json_data.request_exception_DIA === "yes" &&
            json_data.program_name === "Doctorate in Information Assurance (DIA)"
        ) {
            enable("petition_exception")
        }
    }
}
