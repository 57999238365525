import React, {Component} from "react"
import styles from "./Loader.module.css"

interface Props {
    isVisible: boolean
}

export class Loader extends Component<Props, {}> {
    public render() {
        const {isVisible} = this.props
        return (
            <div
                className={`${styles.loadingContainer} ${
                    isVisible ? styles.visible : styles.invisible
                }`}>
                <img src="/finallogo.png" alt="fairfax-logo" className={styles.logo} />
                <div className={styles.LoaderBars}>
                    <div className={styles.Bar}></div>
                    <div className={styles.Bar}></div>
                    <div className={styles.Bar}></div>
                </div>
            </div>
        )
    }
}
