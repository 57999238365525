import {observer} from "mobx-react"
import React, {Component} from "react"
import {observable} from "mobx"
import {FormID} from "../../interfaces"
import {Navigation} from "./parts/Navigation"

interface Props {
    checkedItems: FormID[]
    enabledItems: FormID[]
    selected: FormID
    onChange: (form_id: FormID) => void
    className?: string
}

@observer
export class AdaptiveNavigation extends Component<Props, {}> {
    @observable
    private openedTab: string = null

    @observable
    private mode: "mobile" | "tablet" | "desktop"

    private updateMode() {
        const w = window.innerWidth
        if (w < 660) {
            this.mode = "mobile"
            return
        }
        if (w < 1400) {
            this.mode = "tablet"
            return
        }

        this.mode = "desktop"
    }

    public componentDidMount(): void {
        this.updateMode()

        window.addEventListener("resize", () => {
            this.updateMode()
        })
    }

    public render() {
        return <Navigation {...this.props} mode={this.mode} />
    }
}
