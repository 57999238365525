import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./SignaturePopup.module.css"
import {Gate} from "lib/gate/Gate"
import SignatureCanvas from "react-signature-canvas"
import {action, computed, observable} from "mobx"
import {observer} from "mobx-react"
import {ReactComponent as Icon} from "./parts/img/fill.svg"
import {PopupButton} from "../popupButtons/PopupButton"
import {CloseButton} from "../popupButtons/CloseButton"
import {Model} from "../../Model"

interface Props {
    mode: "signature" | "initials"
    /**
     * If popup opened from PDF form
     */
    signature_key: "student_signature_covid19" | string
    gate: Gate
    model: Model
    onClose: () => void
}

@observer
export class SignaturePopup extends Component<Props, {}> {
    private blankImgData =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAEYklEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlACBB1YxAJfjJb2jAAAAAElFTkSuQmCC"

    @observable
    private currentSignatureImgData: string | undefined

    @observable
    private screenCanvas: boolean = false

    @computed
    private get imgData(): string {
        if (this.currentSignatureImgData) {
            return this.currentSignatureImgData
        }
        return this.blankImgData
    }
    private sigCanvas: typeof SignatureCanvas

    private get signaturePad(): any {
        if (this.imgData === this.blankImgData) {
            return (
                <div className={styles.signatureText}>
                    You do not currently have a signature saved
                </div>
            )
        } else {
            return (
                <img
                    alt=""
                    src={this.imgData}
                    width="250px"
                    height="141px"
                    className={styles.signaturePad}
                />
            )
        }
    }

    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
        this.loadCurrentSignature()
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    public render() {
        console.log("Render:", this.props.mode)
        let html = (
            <article className={styles.popup}>
                <div className={styles.iconWrapper}>
                    <Icon className={styles.icon} width="39" height="39" />
                </div>
                <div className={styles.body}>
                    <div className={styles.closeButtonWrapper}>
                        <CloseButton onClick={this.props.onClose} />
                    </div>

                    {this.props.mode === "signature" && (
                        <h1 className={styles.title}>Manage your signature</h1>
                    )}
                    {this.props.mode === "initials" && (
                        <h1 className={styles.title}>Manage your initials</h1>
                    )}

                    <div className={styles.signatureWrapper}>
                        {this.setCanvasScreen(this.screenCanvas)}
                        {this.signaturePad}
                    </div>

                    <div className={styles.buttonWrapper}>
                        <div className={styles.buttonInner}>
                            <PopupButton
                                text="Clear"
                                onClick={this.onClear.bind(this)}
                                disabled={!this.screenCanvas}
                            />
                            {/*<PopupButton*/}
                            {/*    text="Save"*/}
                            {/*    type="primary"*/}
                            {/*    onClick={this.onSave.bind(this)}*/}
                            {/*    disabled={!this.screenCanvas}*/}
                            {/*/>*/}
                        </div>
                        <PopupButton
                            text="DONE"
                            type="secondary"
                            disabled={!this.screenCanvas}
                            onClick={this.onSave.bind(this)}
                        />
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }

    private async onSave() {
        if (this.sigCanvas.isEmpty()) {
            alert("Can not save empty signature")
            return
        }

        const imgData = this.sigCanvas.toDataURL("image/png")

        await this.props.gate.request("/signatures/add", {
            data: {json_data: {img: imgData}, type: this.props.mode}
        })
        await this.loadCurrentSignature()
        this.sigCanvas.clear()
        this.screenCanvas = false
        if (this.props.model.childBridge) {
            if (this.props.mode === "signature") {
                this.props.model.childBridge.sign(this.props.signature_key)
            }

            if (this.props.mode === "initials") {
                this.props.model.childBridge.signInitials(this.props.signature_key)
            }
        }

        this.props.onClose()
    }

    private onClear() {
        this.sigCanvas.clear()
        this.screenCanvas = false
    }

    private async loadCurrentSignature() {
        let r = await this.props.gate.request("/signatures/get-my", {data: {type: this.props.mode}})
        if (r.success) {
            this.currentSignatureImgData = r.data.json_data.img
            console.log(this.currentSignatureImgData)
        }
    }

    @action
    private setCanvasScreen(screen: boolean) {
        if (screen) {
            return (
                <SignatureCanvas
                    penColor="green"
                    canvasProps={{width: 250, height: 141, className: styles.currentSignature}}
                    ref={(ref) => {
                        this.sigCanvas = ref
                    }}
                />
            )
        } else {
            return (
                <NoSignatureBox
                    onClick={() => {
                        this.screenCanvas = true
                    }}
                />
            )
        }
    }
}

function NoSignatureBox({onClick}: {onClick: () => void}) {
    return (
        <div className={styles.signatureText} onClick={onClick}>
            Enter your signature here. Click to start.
        </div>
    )
}
