export const json = {
    campuses: [
        {
            name: "Roanoke",
            label: "Roanoke/Salem Valley, VA",
            code: "RKE",
            advisors: [
                {name: "Unassigned Advisor", email: "mbansal@an.edu"},
                {name: "Andrea DiTonno", email: "aditonno@an.edu"},
                {name: "Study Metro", email: "abhishek@studymetro.com"},
                {name: "Sonia Asante", email: "sasante@an.edu"},
                {name: "Tuul Bat-Ulziii", email: "tbatulzii@an.edu"},
                {name: "Noelia Guzman Cebrian", email: "nguzmancebrian@an.edu"},
                {name: "Rebekah Custer", email: "rcuster@an.edu"},
                {name: "Amina Ochirbat", email: "aminao@an.edu"},
                {name: "Michael Sutton", email: "msutton@an.edu"},
                {name: "Sivani", email: "pallakishor@gmail.com"},
                {name: "Nirav Savaj", email: "nsavaj@an.edu"},
                {name: "Pennabadi", email: "pennabadiam@gmail.com"},
                {name: "Rashid Wani", email: "rawani@an.edu"},
                {name: "Monica Bansal", email: "mbansal@an.edu"}
                // {name: "ADVISOR TEST", email: "mauricio@klassapp.com"}
            ],
            onlinePrograms: [
                "MSCSM",
                "DIA",
                "IA7000",
                "GRA",
                "DOCBA",
                "MBA",
                "MBACY",
                "MBAIT",
                "MBAPM",
                "MCS",
                "DSD",
                "MSCC"
            ],
            groundPrograms: [
                "MSCSM",
                "DIA",
                "IA7000",
                "GRA",
                "DOCBA",
                "MBA",
                "MBACY",
                "MBAIT",
                "MBAPM",
                "MCS",
                "DSD",
                "MSCC"
            ]
        },
        {
            name: "Louisville",
            label: "Louisville, KY",
            code: "LOU",
            advisors: [
                {name: "Unassigned Advisor", email: "mbansal@an.edu"},
                {name: "Andrea DiTonno", email: "aditonno@an.edu"},
                {name: "Study Metro", email: "abhishek@studymetro.com"},
                {name: "Sonia Asante", email: "sasante@an.edu"},
                {name: "Tuul Bat-Ulziii", email: "tbatulzii@an.edu"},
                {name: "Noelia Guzman Cebrian", email: "nguzmancebrian@an.edu"},
                {name: "Rebekah Custer", email: "rcuster@an.edu"},
                {name: "Amina Ochirbat", email: "aminao@an.edu"},
                {name: "Michael Sutton", email: "msutton@an.edu"},
                {name: "Sivani", email: "pallakishor@gmail.com"},
                {name: "Nirav Savaj", email: "nsavaj@an.edu"},
                {name: "Pennabadi", email: "pennabadiam@gmail.com"},
                {name: "Rashid Wani", email: "rawani@an.edu"},
                {name: "Monica Bansal", email: "mbansal@an.edu"}
                // {name: "ADVISOR TEST", email: "mauricio@klassapp.com"}
            ],
            onlinePrograms: [
                "MSCSM",
                "DIA",
                "IA7000",
                "GRA",
                "DOCBA",
                "MBA",
                "MBACY",
                "MBAIT",
                "MBAPM",
                "MCS",
                "DSD",
                "MSCC"
            ],
            groundPrograms: [
                "MSCSM",
                "DIA",
                "IA7000",
                "GRA",
                "DOCBA",
                "MBA",
                "MBACY",
                "MBAIT",
                "MBAPM",
                "MCS",
                "DSD",
                "MSCC"
            ]
        }
    ],
    programs: [
        {
            code: "MSCSM",
            label: "Master of Science in Cybersecurity Management",
            name: "Master of Science in Cybersecurity Management (MSCSM)"
        },
        {
            code: "DIA",
            label: "Doctorate in Information Assurance",
            name: "Doctorate in Information Assurance (DIA)"
        },
        {
            code: "IA7000",
            label: "CISSP Prep Class (IA7000 Security in the Digital Age)",
            name: "CISSP Prep Class (IA7000 Security in the Digital Age)"
        },
        {
            code: "GRA",
            label: "Graduate Certificate",
            name: "Graduate Certificate"
        },
        {
            code: "DOCBA",
            label: "Doctorate of Business Administration",
            name: "Doctorate of Business Administration"
        },
        {
            code: "MBA",
            label: "General MBA",
            name: "General MBA (MBA)"
        },
        {
            code: "MBACY",
            label: "MBA with Cybersecurity Specialization",
            name: "MBA with Cybersecurity Specialization (MBACY)"
        },
        {
            code: "MBAIT",
            label: "MBA with Information Technology Specialization",
            name: "MBA with Information Technology Specialization (MBAIT)"
        },
        {
            code: "MBAPM",
            label: "MBA with Project Management Specialization",
            name: "MBA with Project Management Specialization (MBAPM)"
        },
        {
            code: "MCS",
            label: "Master of Computer Science and Engineering",
            name: "Master of Computer Science and Engineering (MCS)"
        },
        {
            code: "DSD",
            label: "Doctorate of Software Development",
            name: "Doctorate of Software Development (DSD)"
        },
        {
            code: "DCS",
            label: "Ph.D. in Computer Science & Engineering",
            name: "Ph.D. in Computer Science & Engineering"
        },
        {
            code: "MSCC",
            label: "Master of Science in Cloud Computing",
            name: "Master of Science in Cloud Computing"
        }
    ],
    dates: [
        {value: "Undecided", label: "Undecided"},
        {value: "May 2, 2022", label: "May 2, 2022"},
        {value: "June 27, 2022", label: "June 27, 2022"},
        {value: "August 29, 2022", label: "August 29, 2022"},
        {value: "October 24, 2022", label: "October 24, 2022"},
        {value: "January 2, 2023", label: "January 2, 2023"},
        {value: "February 27, 2023", label: "February 27, 2023"},
        {value: "May 1, 2023", label: "May 1, 2023"},
        {value: "June 26, 2023", label: "June 26, 2023"},
        {value: "August 28, 2023", label: "August 28, 2023"},
        {value: "October 23, 2023", label: "October 23, 2023"}
        // {value: "January 2, 2024", label: "January 2, 2024"},
        // {value: "February 26, 2024", label: "February 26, 2024"},
        // {value: "April 29, 2024", label: "April 29, 2024"},
        // {value: "June 24, 2024", label: "June 24, 2024"},
        // {value: "August 26, 2024", label: "August 26, 2024"},
        // {value: "October 21, 2024", label: "October 21, 2024"}
    ],
    states: [
        {name: "Alabama", abbreviation: "AL"},
        {name: "Alaska", abbreviation: "AK"},
        {name: "Arizona", abbreviation: "AZ"},
        {name: "Arkansas", abbreviation: "AR"},
        {name: "California", abbreviation: "CA"},
        {name: "Colorado", abbreviation: "CO"},
        {name: "Connecticut", abbreviation: "CT"},
        {name: "Delaware", abbreviation: "DE"},
        {name: "District of Columbia", abbreviation: "DC"},
        {name: "Florida", abbreviation: "FL"},
        {name: "Georgia", abbreviation: "GA"},
        {name: "Hawaii", abbreviation: "HI"},
        {name: "Idaho", abbreviation: "ID"},
        {name: "Illinois", abbreviation: "IL"},
        {name: "Indiana", abbreviation: "IN"},
        {name: "Iowa", abbreviation: "IA"},
        {name: "Kansas", abbreviation: "KS"},
        {name: "Kentucky", abbreviation: "KY"},
        {name: "Lousiana", abbreviation: "LA"},
        {name: "Maine", abbreviation: "ME"},
        {name: "Maryland", abbreviation: "MD"},
        {name: "Massachusetts", abbreviation: "MA"},
        {name: "Michigan", abbreviation: "MI"},
        {name: "Minnesota", abbreviation: "MN"},
        {name: "Mississippi", abbreviation: "MS"},
        {name: "Missouri", abbreviation: "MO"},
        {name: "Montana", abbreviation: "MT"},
        {name: "Nebraska", abbreviation: "NE"},
        {name: "Nevada", abbreviation: "NV"},
        {name: "New Hampshire", abbreviation: "NH"},
        {name: "New Jersey", abbreviation: "NJ"},
        {name: "New Mexico", abbreviation: "NM"},
        {name: "New York", abbreviation: "NY"},
        {name: "North Carolina", abbreviation: "NC"},
        {name: "North Dakota", abbreviation: "ND"},
        {name: "Ohio", abbreviation: "OH"},
        {name: "Oklahoma", abbreviation: "OK"},
        {name: "Oregon", abbreviation: "OR"},
        {name: "Pennsylvania", abbreviation: "PA"},
        {name: "Rhode Island", abbreviation: "RI"},
        {name: "South Carolina", abbreviation: "SC"},
        {name: "South Dakota", abbreviation: "SD"},
        {name: "Tennessee", abbreviation: "TN"},
        {name: "Texas", abbreviation: "TX"},
        {name: "Utah", abbreviation: "UT"},
        {name: "Vermont", abbreviation: "VT"},
        {name: "Virginia", abbreviation: "VA"},
        {name: "Washington", abbreviation: "WA"},
        {name: "West Virginia", abbreviation: "WV"},
        {name: "Wisconsin", abbreviation: "WI"},
        {name: "Wyoming", abbreviation: "WY"}
    ],
    tabs: [
        {
            step: "1",
            title: "Application"
        },
        {
            step: "2",
            title: "Education Disclosure"
        },
        {
            step: "3",
            title: "Code of Computing"
        },
        {
            step: "4",
            title: "Felony Acknowledgement Form"
        },
        {
            step: "5",
            title: "Identity Authentication Form"
        },
        {
            step: "6",
            title: "Online Education Disclosure"
        },
        {
            step: "7",
            title: "CPA Eligibility Form"
        },
        {
            step: "8",
            title: "Technical Standards Form"
        },
        {
            step: "9",
            title: "HIM Technical Standards"
        },
        {
            step: "10",
            title: "Transferability of Credits Form"
        },
        {
            step: "11",
            title: "Disclosure of Info Mississippi"
        },
        {
            step: "12",
            title: "Finish & Submit"
        }
    ]
}
