import React, {Component} from "react"
import styles from "./NavigationButton.module.css"
import {Model} from "Model"
import {FormID} from "interfaces"
import {observer} from "mobx-react"
import classNames from "classnames"
import {ReactComponent as Icon} from "./parts/img/icon.svg"
import {computed, observable} from "mobx"
import {
    isEnableSubmitButtonPetitionException,
    isEnableSubmitButtonNoPetitionException
} from "helper/Methods"

interface Props {
    model: Model
    className?: string
}

@observer
export class NavigationButton extends Component<Props, {}> {
    @computed
    private get nextButtonEnabled(): boolean {
        const model = this.props.model
        if (model.getNextFormId() === null) {
            return false
        }
        return true
    }

    @observable
    private submitButtonEnabled = true

    @computed
    private get saveButtonEnabled(): boolean {
        const model = this.props.model
        if (model.form.type === "pdf" && !model.iframeLoaded) {
            return false
        }
        return true
    }

    public render() {
        const {model, className} = this.props
        const {
            document: {json_data}
        } = model
        return (
            <div className={classNames(styles.root, className)}>
                <button
                    className={styles.button}
                    disabled={model.getPreviousFormId() === null}
                    onClick={() => {
                        const form_id: FormID = model.getPreviousFormId()
                        model.setFormId(form_id)
                    }}>
                    <Icon className={styles.icon} width="12" height="12" />
                    BACK
                </button>

                {this.nextButtonEnabled && (
                    <>
                        <button
                            className={classNames(styles.button, styles.buttonSave)}
                            onClick={async () => {
                                const success = await model.save()
                                if (!success) {
                                    // alert("Please check errors")
                                }
                            }}>
                            SAVE
                        </button>
                        <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "signature"
                                }
                            }}>
                            My Signature
                        </button>
                        {/* <button
                            onClick={() => {
                                model.signaturePopup = {
                                    show: true,
                                    signature_key: "",
                                    mode: "initials"
                                }
                            }}>
                            My Initials
                        </button> */}
                        <button
                            className={classNames(styles.button, styles.buttonNext)}
                            disabled={!this.saveButtonEnabled}
                            onClick={async () => {
                                const form_id: FormID = model.getNextFormId()

                                const success = await model.save()
                                if (!success) {
                                    // alert("Please check errors")
                                    return
                                }

                                model.setFormId(form_id)
                            }}>
                            SAVE & CONTINUE <Icon className={styles.icon} width="12" height="12" />
                        </button>
                    </>
                )}

                {!this.nextButtonEnabled && (
                    <button
                        className={classNames(styles.button, styles.buttonNext)}
                        disabled={
                            json_data && json_data.isSubmitted
                                ? true
                                : json_data.request_exception_MSCSM === "yes" &&
                                  json_data.program_name ===
                                      "Master of Science in Cybersecurity Management (MSCSM)"
                                ? isEnableSubmitButtonPetitionException(json_data.forms || [])
                                : json_data.request_exception_DIA === "yes" &&
                                  json_data.program_name ===
                                      "Doctorate in Information Assurance (DIA)"
                                ? isEnableSubmitButtonPetitionException(json_data.forms || [])
                                : isEnableSubmitButtonNoPetitionException(json_data.forms || [])
                        }
                        onClick={async () => {
                            if (!this.props.model.canSubmit()) {
                                alert("Please fill all forms before submit")
                                return
                            }
                            this.submitButtonEnabled = false
                            await model.submit()
                        }}>
                        SUBMIT <Icon className={styles.icon} width="12" height="12" />
                    </button>
                )}
            </div>
        )
    }
}
