import React, {Component} from "react"
import styles from "./CareerInformation.module.css"
import {Label} from "components/inputs/Label"
import {DataStore, SelectOption} from "data/DataStore"
import {JsonDocument, Model} from "../../Model"
import {AdvisorVO, CampusVO, ProgramVO, DateVO} from "data/interfaces"
import {observable} from "mobx"
import {Select} from "components/inputs/Select"
import {CheckBox} from "components/CheckBox"
import {findErrors} from "../../lib/functions/findErrors"
import {observer} from "mobx-react"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const rules = {
    campus: mandatory,
    program_name: mandatory,
    start_date: mandatory,
    advisor_name: mandatory
}

@observer
export class CareerInformation extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate CareerInformation")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private get programs(): SelectOption[] {
        return this.dataStore.getAllPrograms().map((el: ProgramVO) => {
            return {value: el.name, label: el.label}
        })
    }

    private get startingDates(): SelectOption[] {
        let startDate = this.dataStore.getStartDates()
        return startDate.map((el: DateVO) => {
            return {value: el.value, label: el.label}
        })
    }

    private get advisors(): SelectOption[] {
        let advisors: AdvisorVO[] = this.dataStore.getAdvisorsArray(this.json_data.campus)
        if (!advisors) {
            return []
        }

        const firstAdvisor = advisors.shift()

        firstAdvisor.name = "Unassigned Advisor"

        advisors = advisors.sort(function (a, b) {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        })

        advisors.unshift(firstAdvisor)

        const data: SelectOption[] = advisors.map((advisor: AdvisorVO, i) => {
            return {
                label: `${advisor.name} (${advisor.email})`,
                value: `${advisor.email}_${i}`
            }
        })

        return data
    }

    private get campuses(): SelectOption[] {
        return this.dataStore.getCampuses().map((el: CampusVO) => {
            return {value: el.name, label: el.label}
        })
    }

    convertToAdvisorValue = (advisorName: string): string => {
        const advisorNameParts = advisorName.split("_")
        if (advisorNameParts.length > 0) {
            const [value] = advisorNameParts
            return value
        }
        return advisorName
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        if (id === "campus") {
            this.json_data.advisor_name = undefined
            this.json_data.advisor = undefined
            this.json_data.start_date = undefined
            this.json_data.program_name = undefined
        }
        if (id === "advisor_name") {
            this.json_data.advisor = this.convertToAdvisorValue(this.json_data.advisor_name)
        }
        this.props.model.dispatcher2.dispatch()
        this.forceUpdate()
    }

    public render() {
        const model = this.props.model
        // const json_data = model.document.json_data

        const p = {model, onChange: this.onChange, errors: this.errors}

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>WELCOME TO UOF</h2>
                <h2 className={styles.h2}>CAREER INFORMATION</h2>
                <div className={styles.form}>
                    <Label text="Campus" required>
                        <Select id="campus" options={this.campuses} {...p} />
                    </Label>
                    <Label text="Program Name" required>
                        <Select id="program_name" options={this.programs} {...p} />

                        {this.errors["accept_phone_calls"] && (
                            <p style={{color: "red"}}>{this.errors["accept_phone_calls"]}</p>
                        )}

                        <p className={styles.inputText}>
                            If you're undecided, someone from admissions can help you decide
                        </p>
                    </Label>
                </div>

                {/* Specializations for programs */}
                {this.json_data.program_name ===
                    "Master of Science in Cybersecurity Management (MSCSM)" && (
                    <div className={styles.formSpecializations}>
                        <span>Please select the specializations you want to take:</span>
                        <span>
                            <CheckBox
                                id="master_CBP"
                                json_data={this.json_data}
                                onChange={this.onChange}
                            />
                            Cybersecurity Best Practices Certificate (CBP)
                        </span>
                        <span>
                            <CheckBox
                                id="master_ISPP"
                                json_data={this.json_data}
                                onChange={this.onChange}
                            />
                            Information Security Professional Practices Certificate (ISPP)
                        </span>
                        <span>
                            The MSCSM degree program consists of 36 semester credits beyond a
                            baccalaureate degree, including 27 credits of core courses, 3 credits of
                            research methods courses, and 6 credits of elective courses.
                        </span>
                        <span>
                            <CheckBox
                                id="request_exception_MSCSM"
                                json_data={this.json_data}
                                onChange={this.onChange}
                            />
                            I plan to request an exception to one or more admission requirements for
                            this program.
                        </span>
                    </div>
                )}

                {this.json_data.program_name === "Doctorate in Information Assurance (DIA)" && (
                    <div className={styles.formSpecializations}>
                        <span>
                            <CheckBox
                                id="doctorate_EIS"
                                json_data={this.json_data}
                                onChange={this.onChange}
                            />
                            Enterprise Information Security Certificate (EIS)
                        </span>

                        <span>
                            As a DIA student you will identify a challenging, focused, cybersecurity
                            problem relevant to your interests. Then, utilizing existing empirical
                            research you will systematically design a novel solution and assess its
                            feasibility for addressing the problem. Your dissertation will
                            thoroughly document what you did and how you did it. DIA graduates can
                            use their dissertations to pursue publishing opportunities.
                        </span>
                        <span>
                            <CheckBox
                                id="request_exception_DIA"
                                json_data={this.json_data}
                                onChange={this.onChange}
                            />
                            I plan to request an exception to one or more admission requirements for
                            this program.
                        </span>
                    </div>
                )}
                {this.json_data.program_name === "Graduate Certificate" && (
                    <div className={styles.formSpecializations}>
                        <span>
                            <CheckBox
                                id="graduate_CBP"
                                json_data={this.json_data}
                                onChange={this.onChange}
                            />
                            Cybersecurity Best Practices (CBP)
                        </span>
                        <span>
                            <CheckBox
                                id="graduate_ISPP"
                                json_data={this.json_data}
                                onChange={this.onChange}
                            />
                            Information Security Professional Practices (ISPP)
                        </span>
                        <span>
                            <CheckBox
                                id="graduate_EIS"
                                json_data={this.json_data}
                                onChange={this.onChange}
                            />
                            Enterprise Information Security (EIS)
                        </span>
                    </div>
                )}

                <div className={styles.form2}>
                    <Label text="Starting Term" required>
                        <Select id="start_date" options={this.startingDates} {...p} />
                    </Label>

                    <Label text="Advisor" required>
                        <Select id="advisor_name" options={this.advisors} {...p} />
                        <p className={styles.inputText}>
                            If you have not worked with an admissions advisor, select "Unassigned"
                        </p>
                    </Label>
                </div>
                <p className={styles.text}>
                    <span>*</span> Indicates that a response is required
                </p>
            </div>
        )
    }
}
