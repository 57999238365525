import * as React from "react"
import {Component} from "react"
import {JsonDocument} from "../Model"

interface Props {
    id: string
    json_data: JsonDocument
    onChange: (id, value) => void
}

export class CheckBox extends Component<Props, {}> {
    public render() {
        const {id, onChange, json_data} = this.props
        let checked: boolean = json_data[id] === "yes"

        return (
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={(event) => {
                    let value: "yes" | "no" = "no"
                    if (event.target.checked) {
                        value = "yes"
                    }
                    onChange(id, value)
                }}
            />
        )
    }
}
