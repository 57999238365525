import React, {Component} from "react"
import classNames from "classnames"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import styles from "./UploadDocs.module.css"
import {UploadInput} from "./upload-input"
// import {RadioButton} from "components/inputs/RadioButton"
// import {LabelRadio} from "components/inputs/LabelRadio"
// import {Label} from "components/inputs/Label"
// import {DatePicker} from "components/DatePicker"
// import setHours from "date-fns/setHours"
// import setMinutes from "date-fns/setMinutes"

interface Props {
    model: Model
}

// const mandatory = {
//     presence: {allowEmpty: false}
// }

const rules = {
    // schedule_appointment: mandatory,
}

@observer
export class UploadDocs extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate EmploymentDetails")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        if (this.json_data.isSubmitted) {
            delete this.json_data.isSubmitted
        }
        this.json_data[id] = value
        this.forceUpdate()
    }

    public render() {
        const {model} = this.props
        const uploads = this.props.model.document.json_data.uploads || []
        // const r = {onChange: this.onChange, json_data: this.json_data}
        const p = {json_data: this.json_data, onChange: this.onChange, errors: this.errors}
        // const isWeekday = (date) => {
        //     const day = date.getDay(date)
        //     return day !== 0 && day !== 6
        // }

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>Upload Documents</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>General Documents</h3>
                <UploadInput model={model} title={`Resume`} btnId={`resume`} uploads={uploads} />
                <UploadInput
                    model={model}
                    title={`Unofficial Transcript`}
                    btnId={`unofficialTranscript`}
                    uploads={uploads}
                />
                <UploadInput model={model} title={`DD-214`} btnId={`DD214`} uploads={uploads} />
                <h3 className={classNames(styles.h3, styles.hidden)}>Graduate Essay</h3>

                <p className={styles.working}>
                    TOPIC:{" "}
                    <strong>Why have you decided to pursue a graduate program at this time?</strong>
                </p>
                <p className={styles.working}>
                    The essay must be 700 words in length and can be submitted in any format (Word,
                    PDF, etc.). Please be sure your work is grammatically correct and cites
                    appropriate sources.
                </p>
                <div style={{marginTop: "60px"}}></div>
                <UploadInput
                    model={model}
                    title={`Graduate Program Essay`}
                    btnId={`graduateProgramEssay`}
                    uploads={uploads}
                />
                {/* <div className="row">
                    <div className={styles.form2}>
                        <LabelRadio
                            text="Would you like to schedule an appointment to speak with the Financial Aid Advisor?"
                            error={this.errors["schedule_appointment"]}
                            className={styles.radioLabel}
                            required>
                            <>
                                <RadioButton
                                    label="Yes"
                                    value="yes"
                                    group_name="schedule_appointment"
                                    {...r}
                                />
                                <RadioButton
                                    label="No"
                                    value="no"
                                    group_name="schedule_appointment"
                                    {...r}
                                />
                            </>
                        </LabelRadio>
                        {this.json_data.schedule_appointment === "yes" && (
                            <div className={styles.form}>
                                <h3 className={classNames(styles.h3, styles.hidden)}>
                                    The Financial Aid Advisor is available
                                </h3>
                                <p className={styles.working}>
                                    (Time is in US Eastern Timezone. Financial Aid advisor will
                                    contact you within 1 hour of selected time slot)
                                </p>

                                <Label text="Date" className={styles.label}>
                                    <DatePicker
                                        id="schedule_time"
                                        json_data={this.json_data}
                                        onChange={this.onChange}
                                        errors={this.errors}
                                        filterDate={isWeekday}
                                        showTimeSelect={true}
                                        minTime={setHours(setMinutes(new Date(), 0), 9)}
                                        maxTime={setHours(setMinutes(new Date(), 30), 16)}
                                    />
                                </Label>
                            </div>
                        )}
                    </div>
                </div> */}
            </div>
        )
    }
}
