import {FormID} from "../interfaces"

const ALL_FORMS_IN_TABS = [
    "application_career_information",
    "application",
    "application_admission",
    "program_declaration",
    "petition_exception",
    "financial_aid",
    "application_upload_docs",
    "submission"
]

const ALL_FORMS = [
    "application",
    "application_admission",
    "program_declaration",
    "petition_exception",
    "financial_aid",
    "application_upload_docs"
]

const ALL_FORMS_NO_PETITION_EXCEPTION = [
    "application",
    "application_admission",
    "program_declaration",
    "financial_aid",
    "application_upload_docs"
]

const isEnableSubmitButton = (currentJsonDataForms) => {
    const filledForms = ALL_FORMS.length
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (ALL_FORMS.includes(elementKey as FormID)) counter += 1
    }
    return counter === filledForms ? false : true
}

const isEnableSubmitButtonPetitionException = (currentJsonDataForms) => {
    const filledForms = ALL_FORMS.length
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (ALL_FORMS.includes(elementKey as FormID)) counter += 1
    }
    return counter === filledForms ? false : true
}

const isEnableSubmitButtonNoPetitionException = (currentJsonDataForms) => {
    const filledForms = ALL_FORMS_NO_PETITION_EXCEPTION.length
    const jsonFormsKeys = Object.keys(currentJsonDataForms)
    let counter = 0
    for (let i = 0; i < jsonFormsKeys.length; i++) {
        const elementKey = jsonFormsKeys[i]
        if (ALL_FORMS_NO_PETITION_EXCEPTION.includes(elementKey as FormID)) counter += 1
    }
    return counter === filledForms ? false : true
}

export {
    isEnableSubmitButton,
    isEnableSubmitButtonPetitionException,
    isEnableSubmitButtonNoPetitionException,
    ALL_FORMS_IN_TABS,
    ALL_FORMS,
    ALL_FORMS_NO_PETITION_EXCEPTION
}
