import React, {Component} from "react"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import Swal from "sweetalert2"
import styles from "./UploadInput.module.css"

interface Props {
    title: string
    btnId: string
    uploads: any
    model: Model
}

@observer
export class UploadInput extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    @observable
    private staticFile = {
        id: -1,
        name: "",
        url: ""
    }

    private json_data: JsonDocument = {}

    private CONSTANT_STATIC_PATH = "/uploads"

    private FILENAME_DASH = "_"
    private FILENAME_DOT = "."
    private START_INDEX_FILENAME = 0

    constructor(props) {
        super(props)
        this.json_data = props.model.document.json_data

        if (this.json_data.uploads && this.json_data.uploads.length > 0) {
            const findStaticFile = this.json_data.uploads.find(
                ({type}) => type === this.props.btnId
            )
            if (findStaticFile) {
                this.staticFile = {...findStaticFile}
                this.staticFile.url = findStaticFile.path
                this.staticFile.name = this.cutFilename(findStaticFile.name)
            }
        }
        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
    }

    public async componentWillUnmount() {
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private onHandleChangeButtonUpload = async (id: string, {target: {files}}) => {
        console.log("UploadInput:", {id, files})

        const fileSize = files.item(0).size / 1024 / 1024 // in MB

        if (files.length > 0 && fileSize < 6) {
            // if fileSize is below 6MB it will be uploaded
            const [aFile] = files
            const response = await this.props.model.uploadDocument(aFile, id)
            if (response.success) {
                if (this.json_data.isSubmitted) {
                    delete this.json_data.isSubmitted
                }
                this.replaceCurrentUploadFIle(id, response.data)
            }
        } else {
            console.log("File size exceeds 6 MiB", fileSize)
            Swal.fire({
                icon: "warning",
                title: "Maximum file size reached",
                text:
                    "Your file size is above 6MB, please compress your image or file and then try again."
            })
        }
        this.forceUpdate()
    }

    private replaceLastIndexOf = (aPath) =>
        aPath.substring(aPath.lastIndexOf(this.CONSTANT_STATIC_PATH), aPath.length)

    private cutFilename = (fileName) => {
        if (fileName.lastIndexOf(this.FILENAME_DOT) > -1) {
            const lastIndexOfDash = fileName.lastIndexOf(this.FILENAME_DASH)
            const lastIndexOfDot = fileName.lastIndexOf(this.FILENAME_DOT)
            const newName = `${fileName.substring(
                this.START_INDEX_FILENAME,
                lastIndexOfDash
            )}${fileName.substring(lastIndexOfDot, fileName.length)}`
            return newName
        }
        return fileName
    }

    private replaceCurrentUploadFIle = (id, file) => {
        const uploads = this.props.uploads
        if (uploads.length > 0) {
            const foundIndexUpload = uploads.findIndex((aFile) => aFile.type === id)
            if (foundIndexUpload > -1) {
                uploads[foundIndexUpload] = {...file}
                this.staticFile = {...file}
                this.staticFile.url = uploads[foundIndexUpload].path
                this.staticFile.name = this.cutFilename(uploads[foundIndexUpload].name)
            } else {
                uploads.push(file)
                const foundFile = uploads.find((aFile) => aFile.type === id)
                this.staticFile = {...foundFile}
                this.staticFile.url = foundFile.path
                this.staticFile.name = this.cutFilename(foundFile.name)
            }
        } else {
            uploads.push(file)
            const [first] = uploads
            this.staticFile = {...file}
            this.staticFile.url = first.path
            this.staticFile.name = this.cutFilename(file.name)
        }
        this.json_data.uploads = uploads
    }
    private showDocument = () => {
        const uploads = this.props.uploads
        if (uploads.length > 0) {
            const foundIndex = this.props.uploads.findIndex(
                (aFile) => aFile.type === this.props.btnId
            )
            if (foundIndex > -1) {
                return true
            }
            return false
        }
        return false
    }

    public render() {
        const {title, btnId} = this.props

        const {onHandleChangeButtonUpload, showDocument, staticFile} = this

        return (
            <div className={styles.form}>
                <div className={styles.root}>
                    <input
                        type="file"
                        // accept="image/x-png,image/gif,image/jpeg"
                        id={btnId}
                        onChange={(e) => {
                            onHandleChangeButtonUpload(btnId, e)
                        }}
                        hidden
                    />
                    <label htmlFor={btnId}>{title}</label>
                </div>
                {showDocument() && (
                    <div className={styles.fileImg}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{staticFile.name}</td>
                                    <td>
                                        <a
                                            href={staticFile.url}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <i className="fas fa-eye fa-lg"></i>
                                        </a>
                                    </td>
                                    <td
                                        onClick={async () => {
                                            await Swal.fire({
                                                icon: "warning",
                                                title: "Are you sure?",
                                                text: "Document will be deleted",
                                                showCancelButton: true,
                                                confirmButtonColor: "var(--tertiary-600)",
                                                cancelButtonColor: "var(--warning-400-base)",
                                                confirmButtonText: "Delete"
                                            }).then((result) => {
                                                if (result.value) {
                                                    const model = this.props.model

                                                    model.deleteSupportedDocument(
                                                        this.staticFile.id
                                                    )
                                                    try {
                                                        const filteredUploadedDocs = this.json_data.uploads.filter(
                                                            (uploadDoc) =>
                                                                uploadDoc.id !== this.staticFile.id
                                                        )
                                                        this.json_data.uploads = filteredUploadedDocs
                                                        model.save()
                                                        Swal.fire({
                                                            icon: "success",
                                                            title: "Success",
                                                            text: "Document has been deleted"
                                                        })

                                                        this.forceUpdate()
                                                    } catch (error) {
                                                        Swal.fire({
                                                            icon: "error",
                                                            title: "Error",
                                                            text: "Document has not been deleted"
                                                        })
                                                    }
                                                }
                                            })
                                        }}>
                                        <i className="fas fa-trash-alt fa-lg"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        )
    }
}
