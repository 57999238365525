import React, {Component} from "react"
import styles from "./Navigation.module.css"
import classNames from "classnames"
import {FormID} from "interfaces"
import {observer} from "mobx-react"
import {Item} from "./parts/Item"
import {observable} from "mobx"

interface Props {
    checkedItems: FormID[]
    enabledItems: FormID[]
    selected: FormID
    onChange: (form_id: FormID) => void
    mode: "mobile" | "tablet" | "desktop"
    className?: string
}

const tabs = [
    {
        id: "1",
        title: "Career Information",
        tabletTitle: "Career Info",
        items: [
            {
                label: "Program Selection",
                value: "application_career_information"
            }
        ]
    },
    {
        id: "2",
        title: "Forms",
        tabletTitle: "Forms",
        items: [
            {value: "application", label: "Application"},
            {value: "application_admission", label: "Application Admission"},
            {value: "program_declaration", label: "Program Declaration"},
            {value: "petition_exception", label: "Petition Exception"},
            {value: "financial_aid", label: "Financial Aid"}
        ]
    },
    {
        id: "3",
        title: "Upload Docs",
        tabletTitle: "Upload Docs",
        items: [
            {
                label: "Upload Docs",
                value: "application_upload_docs"
            }
        ]
    },
    {
        id: "4",
        title: "Submission",
        tabletTitle: "Submission",
        items: [
            {
                label: "Submission",
                value: "submission"
            }
        ]
    }
]

@observer
export class Navigation extends Component<Props, {}> {
    @observable
    private openedTab: string = null

    public componentDidMount(): void {
        if (this.props.mode === "desktop") {
            this.openedTab = this.getTabIdByFormId(this.props.selected)
        } else {
            this.openedTab = null
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.selected === prevProps.selected) {
            return
        }

        if (this.props.mode === "desktop") {
            this.openedTab = this.getTabIdByFormId(this.props.selected)
        } else {
            this.openedTab = null
        }
    }

    private getTabIdByFormId(form_id: FormID): string {
        for (const tab of tabs) {
            const hasForm = tab.items.find((el) => {
                if (el.value === form_id) {
                    return true
                }
                return false
            })
            if (hasForm) {
                return tab.id
            }
        }

        return null
    }

    public render() {
        const {selected, onChange, className} = this.props
        return (
            <div className={classNames(styles.root, className)}>
                <ul className={styles.list}>
                    {tabs.map((el) => {
                        return (
                            <Item
                                key={el.id}
                                id={el.id}
                                title={el.title}
                                tabletTitle={el.tabletTitle}
                                items={el.items as any}
                                onTitleClick={(tab_id) => {
                                    console.log("TAB " + tab_id)
                                    this.openedTab = tab_id
                                }}
                                onClick={(id: FormID) => {
                                    if (this.props.mode !== "desktop") {
                                        // Close submenu for tablet and mobile
                                        this.openedTab = null
                                    }
                                    onChange(id)
                                }}
                                selected={selected}
                                checkedItems={this.props.checkedItems}
                                enabledItems={this.props.enabledItems}
                                isOpen={this.openedTab === el.id}
                            />
                        )
                    })}
                </ul>
            </div>
        )
    }
}
